import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Heading,
  HStack,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import CreateSimulatorModal from '../components/CreateSimulator';
import SimulatorTable from '../components/SimulatorsTable';
import { Simulator } from '../utils/types';
import { useApiClient } from '../utils/useApiClient';

export function SimulatorsPage() {
  const apiClient = useApiClient();

  const { status, data, refetch } = useQuery(
    'simulators',
    async () => {
      return await apiClient.get<Simulator[]>('/simulators');
    },
    { refetchInterval: 2000 },
  );
  const createSimulatorModal = useDisclosure();

  return (
    <VStack>
      <Center>
        <Heading>Simulators</Heading>
      </Center>
      <HStack width="4xl" padding={2} border="1px" borderRadius={'4px'} borderColor="gray.300">
        <Button onClick={createSimulatorModal.onOpen}>Create simulator</Button>
        <CreateSimulatorModal
          onSuccess={async () => {
            await refetch();
          }}
          onClose={createSimulatorModal.onClose}
          isOpen={createSimulatorModal.isOpen}
        />
      </HStack>

      {data && <SimulatorTable simulators={data} />}
      {status === 'error' ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error while fetching the data.</AlertTitle>
        </Alert>
      ) : !data ? (
        <Alert status="warning">
          <AlertIcon />
          <AlertTitle mr={2}>No data was found.</AlertTitle>
        </Alert>
      ) : undefined}
    </VStack>
  );
}
