import { Center, HStack, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export default function Navigation() {
  return (
    <Center bg="blue.700" textColor="white" h="10" mb="5">
      <HStack width="4xl" justifyContent="space-between">
        <Link as={RouterLink} to="/">
          Home
        </Link>
        <Link as={RouterLink} to="/logout">
          Logout
        </Link>
      </HStack>
    </Center>
  );
}
