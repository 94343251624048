import { useContext } from 'react';
import { AuthenticationContext } from './AuthenticationProvider';

export interface User {
  name: string;
  'https://keat.io/audiences'?: string[];
}

export function useAuthentication() {
  const ctx = useContext(AuthenticationContext);

  if (!ctx) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider');
  }

  const auth = useContext(ctx);

  return {
    ...auth,
    user: auth.user as User,
  };
}
