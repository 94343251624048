import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { PlatformType } from '@tp-vision/displays';
import { isEmpty } from 'lodash';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useApiClient } from '../utils/useApiClient';

type CreateSimulatorModalProps = {
  onClose: () => Promise<void> | void;
  onSuccess: () => Promise<void> | void;
  isOpen: boolean;
};

export default function CreateSimulatorModal({
  isOpen,
  onClose,
  onSuccess,
}: CreateSimulatorModalProps) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <CreateSimulatorModalContent
          onSuccess={onSuccess}
          onClose={onClose}
          initialFocusRef={initialFocusRef}
        />
      </ModalContent>
    </Modal>
  );
}

const schema = z.object({
  commercialTypeNumber: z.string().optional(),
  platform: z.string().optional(),
  version: z.string().optional(),
  agentVersion: z.string().regex(/^(0|[1-9][0-9]*)\.(0|[1-9][0-9]*)\.(0|[1-9][0-9]*)$/),
});

type CreateSimulatorModalContentProps = {
  onClose: () => Promise<void> | void;
  onSuccess: () => Promise<void> | void;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
};

function CreateSimulatorModalContent({
  onClose,
  onSuccess,
  initialFocusRef,
}: CreateSimulatorModalContentProps) {
  const apiClient = useApiClient();
  const [displayType, setDisplayType] = useState<string>(PlatformType.Signage);

  const performSubmit = useCallback(
    async ({ commercialTypeNumber, platform, version, agentVersion }: z.TypeOf<typeof schema>) => {
      const body: Record<string, unknown> = {};

      if (displayType !== PlatformType.Signage && displayType !== PlatformType.ProTV) {
        console.error('Invalid display type.', displayType);
        return;
      }

      body.type = displayType;

      if (!isEmpty(commercialTypeNumber?.trim())) {
        body.commercialTypeNumber = commercialTypeNumber?.trim();
      }

      if (!isEmpty(platform?.trim())) {
        body.platform = {
          name: platform?.trim(),
          version: isEmpty(version?.trim()) ? undefined : version?.trim(),
          type: displayType,
        };
      }

      body.agentVersion = agentVersion.trim();

      await apiClient.post(`/create`, body);

      await onSuccess();
      await onClose();
    },
    [displayType, apiClient, onSuccess, onClose],
  );

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    defaultValues: {
      platform: undefined,
      version: undefined,
      commercialTypeNumber: undefined,
      agentVersion: '',
    },
    resolver: zodResolver(schema),
  });

  const hasErrors = !isValid;

  const {
    ref: ctnInputRef,
    onChange: onCtnChange,
    ...ctnInputProps
  } = register('commercialTypeNumber');

  const {
    ref: platformInputRef,
    onChange: onPlatformChange,
    ...platformInputProps
  } = register('platform');

  const {
    ref: versionInputRef,
    onChange: onVersionChange,
    ...versionInputProps
  } = register('version');

  const {
    ref: agentVersionInputRef,
    onChange: onAgentVersionChange,
    ...agentVersionInputProps
  } = register('agentVersion');

  return (
    <form onSubmit={handleSubmit(performSubmit)}>
      <ModalHeader>Create Simulator</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <RadioGroup onChange={setDisplayType} value={displayType}>
          <HStack>
            <Radio value={PlatformType.Signage}>Signage</Radio>
            <Radio value={PlatformType.ProTV}>ProTV</Radio>
          </HStack>
        </RadioGroup>

        <HStack>
          <FormLabel htmlFor="platform" mt={3}>
            Platform
          </FormLabel>
          <FormControl isInvalid={Boolean(errors?.platform)}>
            <Input
              id="platform"
              placeholder="optional"
              ref={(ref) => {
                initialFocusRef.current = ref;
                platformInputRef(ref);
              }}
              onChange={onPlatformChange}
              {...platformInputProps}
            />
            <FormErrorMessage>{errors.platform?.message}</FormErrorMessage>
          </FormControl>

          {displayType === PlatformType.Signage && (
            <>
              <FormLabel htmlFor="version" mt={3}>
                Version
              </FormLabel>
              <FormControl isInvalid={Boolean(errors?.version)}>
                <Input
                  id="version"
                  placeholder="optional"
                  ref={(ref) => {
                    initialFocusRef.current = ref;
                    versionInputRef(ref);
                  }}
                  onChange={onVersionChange}
                  {...versionInputProps}
                />
                <FormErrorMessage>{errors.version?.message}</FormErrorMessage>
              </FormControl>
            </>
          )}
        </HStack>

        <HStack mt={3}>
          <FormControl
            isInvalid={Boolean(errors?.agentVersion)}
            isRequired
            display="flex"
            alignItems="center"
          >
            <FormLabel flexShrink={0} htmlFor="agentVersion">
              Agent version
            </FormLabel>
            <Input
              id="agentVersion"
              ref={(ref) => {
                initialFocusRef.current = ref;
                agentVersionInputRef(ref);
              }}
              onChange={onAgentVersionChange}
              {...agentVersionInputProps}
            />
            <FormErrorMessage>{errors.agentVersion?.message}</FormErrorMessage>
          </FormControl>
        </HStack>

        <FormLabel htmlFor="ctn" mt={3}>
          Commercial type number
        </FormLabel>

        <FormControl isInvalid={Boolean(errors?.commercialTypeNumber?.message)}>
          <Input
            id="ctn"
            placeholder="optional"
            ref={(ref) => {
              initialFocusRef.current = ref;
              ctnInputRef(ref);
            }}
            onChange={onCtnChange}
            {...ctnInputProps}
          />
          <FormErrorMessage>{errors.commercialTypeNumber?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          type="submit"
          colorScheme="blue"
          isDisabled={isSubmitting || hasErrors}
          isLoading={isSubmitting}
        >
          Create
        </Button>
      </ModalFooter>
    </form>
  );
}
