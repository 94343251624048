import { CopyIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { useQuery } from 'react-query';
import { Simulator } from '~utils/types';
import { useApiClient } from '../utils/useApiClient';

interface RequestCodeResponse {
  displayCode: string;
  expirationTime: string;
}

interface Props {
  simulator: Simulator;
}

export default function ShowCode({ simulator }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} isDisabled={simulator.connectionState !== 'connected'}>
        Request Code
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ShowCodeModalContent simulator={simulator} onClose={onClose} />
      </Modal>
    </>
  );
}

function ShowCodeModalContent({
  simulator,
  onClose,
}: {
  simulator: Simulator;
  onClose: () => void;
}) {
  const apiClient = useApiClient();
  const { status, data } = useQuery('code', async () => {
    const data = await apiClient.get<RequestCodeResponse>(
      `/simulators/${simulator.id}/requestCode`,
    );
    return {
      ...data,
      expirationTime: format(new Date(data.expirationTime), 'HH:m:s'),
    };
  });

  const { onCopy } = useClipboard(data?.displayCode ?? '');

  return (
    <ModalContent>
      <ModalHeader>Request Code</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        {status === 'success' && data ? (
          <>
            <HStack spacing="2">
              <Text fontSize="2xl" fontWeight="bold">
                {data.displayCode}
              </Text>
              <Button onClick={onCopy} variant="ghost">
                <CopyIcon />
              </Button>
            </HStack>
            <Text>Expires at {data.expirationTime}</Text>
          </>
        ) : (
          <Text>Loading</Text>
        )}
      </ModalBody>

      <ModalFooter>
        <Button variant="ghost" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
