import {
  Checkbox,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Simulator } from '~utils/types';
import { useApiClient } from '../utils/useApiClient';

interface Props {
  simulator: Simulator;
  onSuccess: () => Promise<void> | void;
}

const schema = z.object({
  min: z.number().nullable(),
});

type FormValues = z.TypeOf<typeof schema>;

export function VolumeMinInlineEdit({ simulator, onSuccess }: Props) {
  const max = useMemo(() => simulator.shadow.state.volumeMax?.value ?? 100, [simulator]);
  const schema = useMemo(
    () =>
      z.object({
        min: z.number().nullable().optional(),
      }),
    [],
  );

  const apiClient = useApiClient();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      min: simulator.shadow.state.volumeMin?.value ?? 0,
    },
    resolver: zodResolver(schema),
  });

  const performSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await apiClient.post(`/simulators/${simulator.id}/changeVolumeMin`, {
          min: values.min,
        });

        onSuccess();
      } catch (err) {
        console.error('An error occurred trying to update volume min', err);
      }
    },
    [onSuccess, simulator, apiClient],
  );

  const handleOnBlur = useCallback(() => {
    if (!isValid) return;

    handleSubmit(performSubmit)();
  }, [isValid, handleSubmit, performSubmit]);

  return (
    <form onSubmit={handleSubmit(performSubmit)}>
      <Controller
        name="min"
        control={control}
        render={({ field }) => (
          <Stack direction="row" spacing="2">
            <Checkbox
              isChecked={!isNil(field.value)}
              onChange={() => {
                field.onChange(isNil(field.value) ? 0 : null);
                handleOnBlur();
              }}
            ></Checkbox>
            <NumberInput
              value={field.value ?? undefined}
              onChange={(_, val) => field.onChange(val)}
              onBlur={handleOnBlur}
              min={0}
              max={max}
              isDisabled={isNil(field.value)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Stack>
        )}
      />
    </form>
  );
}
