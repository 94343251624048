import { Auth0Context, Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useNavigate } from 'react-router';
import AuthenticationProvider from './auth/AuthenticationProvider';
import theme from './theme';

const queryClient = new QueryClient();

function AppProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: Record<string, string>) => {
      if (!appState?.returnTo) return;
      navigate(appState.returnTo, { replace: true });
    },
    [navigate],
  );

  return (
    <ChakraProvider resetCSS theme={theme}>
      <AuthenticationProvider
        provider={Auth0Provider}
        providerProps={{
          domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
          clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
          authorizationParams: {
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: process.env.REACT_APP_AUTH0_SCOPE,
            onRedirectCallback,
          },
        }}
        context={Auth0Context}
      >
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </AuthenticationProvider>
    </ChakraProvider>
  );
}

export default AppProvider;
