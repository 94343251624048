import { Alert, AlertIcon, AlertTitle, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Simulator } from '~utils/types';
import SimulatorDetailsActions from '../components/SimulatorDetailsActions';
import { useApiClient } from '../utils/useApiClient';

export default function SimulatorPage() {
  const { id } = useParams();

  return <SimulatorDetails id={id} />;
}

interface Props {
  id: string | undefined;
}

function SimulatorDetails({ id }: Props) {
  const apiClient = useApiClient();

  const { status, data, refetch } = useQuery(
    'simulator',
    async () => apiClient.get<Simulator>(`/simulators/${id}`),
    { refetchInterval: 2000 },
  );

  return (
    <VStack spacing="20">
      <Center display={'flex'} flexDirection={'column'}>
        <Heading>Simulator Details</Heading>
        <Text marginTop={3}>{data?.metadata.alias ?? data?.configuration.serialNumber}</Text>
      </Center>

      {data && <SimulatorDetailsActions simulator={data} doRefetch={refetch} />}

      {status === 'error' ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error while fetching the data.</AlertTitle>
        </Alert>
      ) : !data ? (
        <Alert status="warning">
          <AlertIcon />
          <AlertTitle mr={2}>No data was found.</AlertTitle>
        </Alert>
      ) : undefined}
    </VStack>
  );
}
