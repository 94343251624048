import { NumberInput, NumberInputField } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Simulator } from '~utils/types';
import { useApiClient } from '../utils/useApiClient';

interface Props {
  simulator: Simulator;
  onSuccess: () => Promise<void> | void;
}

type FormValues = z.TypeOf<typeof FormValues>;
const FormValues = z.object({
  availableStorage: z.number(),
});

export function StorageAvailableInlineEdit({ simulator, onSuccess }: Props) {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    defaultValues: {
      availableStorage: simulator.shadow.state.availableStorage?.value,
    },
    resolver: zodResolver(FormValues),
  });

  const apiClient = useApiClient();

  const performSubmit = useCallback(
    async ({ availableStorage }: FormValues) => {
      try {
        await apiClient.post(`/simulators/${simulator.id}/changeStorage`, {
          availableStorage,
        });

        onSuccess();
      } catch (err) {
        console.error('An error occured trying to update volume min', err);
      }
    },
    [onSuccess, simulator, apiClient],
  );

  const handleOnBlur = useCallback(() => {
    if (!isValid) return;

    handleSubmit(performSubmit)();
  }, [isValid, handleSubmit, performSubmit]);

  return (
    <form onSubmit={handleSubmit(performSubmit)}>
      <Controller
        name="availableStorage"
        control={control}
        render={({ field }) => (
          <NumberInput
            value={field.value ?? undefined}
            onChange={(_, val) => field.onChange(val)}
            onBlur={handleOnBlur}
            min={0}
            isDisabled={isNil(field.value)}
          >
            <NumberInputField />
          </NumberInput>
        )}
      />
    </form>
  );
}
