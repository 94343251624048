import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { PlatformType } from '@tp-vision/displays';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';
import { z } from 'zod';
import { Simulator } from '~utils/types';
import { useApiClient } from '../utils/useApiClient';

const schemaSignage = z.object({
  scaler: z.string().optional(),
  android: z.string().regex(/^[0-9]{2}[.][0-9]{2}$/, 'Invalid android firmware version.'),
});

const schemaProTv = z.object({
  scaler: z.string().optional(),
  android: z
    .string()
    .regex(/^[0-9]{3}[.][0-9]{3}[.][0-9]{3}[.][0-9]{3}$/, 'Invalid android firmware version.'),
});

interface Props {
  simulator: Simulator;
  refetch: () => Promise<QueryObserverResult<Simulator, unknown>>;
}

export default function ModalFirmware({ simulator, refetch }: Props) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isSimulatorProTv = simulator.configuration.platform.name.startsWith('TPM');

  async function onSuccess() {
    await refetch();
    onClose();
  }

  return (
    <>
      <Button onClick={onOpen} isDisabled={simulator.connectionState !== 'connected'}>
        Flash Firmware
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
        <ModalOverlay />
        <ModalContent>
          <FlashFirmwareModalContent
            initialFocusRef={initialFocusRef}
            onSuccess={onSuccess}
            onCancel={onClose}
            simulator={simulator}
            isProtv={isSimulatorProTv}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

type ContentProps = {
  simulator: Simulator;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  isProtv: boolean;
  onCancel: () => Promise<void> | void;
  onSuccess: () => Promise<void> | void;
};

function FlashFirmwareModalContent({
  simulator,
  initialFocusRef,
  isProtv,
  onSuccess,
  onCancel,
}: ContentProps) {
  const schema = isProtv ? schemaProTv : schemaSignage;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      android: '',
    },
    resolver: zodResolver(schema),
  });

  const {
    ref: androidInputRef,
    onChange: onAndroidChange,
    ...androidInputProps
  } = register('android');

  const apiClient = useApiClient();

  const onSubmit = useCallback(
    async ({ android, scaler }: z.TypeOf<typeof schema>) => {
      try {
        const prefix = isProtv ? 'R.' : 'FB';

        await apiClient.post(`/simulators/${simulator.id}/firmware`, {
          type: isProtv ? PlatformType.ProTV : PlatformType.Signage,
          android: prefix.concat(android),
          scaler,
        });

        await onSuccess();
      } catch (error) {
        console.log({ error }, 'ERROR');
      }
    },
    [isProtv, apiClient, simulator.id, onSuccess],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Flash Firmware</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <FormLabel htmlFor="ctn">Firmware</FormLabel>
        <FormControl isInvalid={Boolean(errors.android)}>
          <InputGroup>
            <InputLeftAddon>{!isProtv ? 'FB' : 'R.'}</InputLeftAddon>

            <Input
              focusBorderColor="none"
              onChange={onAndroidChange}
              ref={(r) => {
                androidInputRef(r);
                initialFocusRef.current = r;
              }}
              {...androidInputProps}
              isInvalid={Boolean(errors.android)}
              placeholder={
                !isProtv
                  ? simulator.shadow.state.firmwareVersion?.value.split('FB')[1] ?? '01.05'
                  : simulator.shadow.state.firmwareVersion?.value.split('R.')[1] ??
                    '205.001.001.001'
              }
              size="md"
            />
          </InputGroup>

          <FormErrorMessage>{errors.android?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>

      <ModalFooter mt={3}>
        <Button variant="ghost" onClick={onCancel} isDisabled={isSubmitting}>
          Close
        </Button>

        <Button type="submit" colorScheme="blue" isDisabled={isSubmitting} isLoading={isSubmitting}>
          Send
        </Button>
      </ModalFooter>
    </form>
  );
}
