import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import PrivateRoute from './auth/PrivateRoute';
import { useAuthentication } from './auth/useAuthentication';
import { LoginPage } from './pages/Login';
import { LogoutPage } from './pages/Logout';
import { NotFoundPage } from './pages/NotFound';
import SimulatorPage from './pages/Simulator';
import { SimulatorsPage } from './pages/Simulators';

function App() {
  const { isLoading } = useAuthentication();

  return (
    <>
      {isLoading ? (
        <Center flex={1}>
          <Spinner size="lg" />
        </Center>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="simulators" element={<SimulatorsPage />} />
              <Route path="simulators/:id" element={<SimulatorPage />} />
              <Route path="/" element={<Navigate to="simulators" />} />
            </Route>

            <Route path={'/login'} element={<LoginPage />} />
            <Route path={'/logout'} element={<LogoutPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
