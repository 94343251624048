import { isEmpty } from 'lodash';
import { useAuthentication } from '../auth/useAuthentication';

interface ApiError {
  code: string;
  error: string;
  message: string;
  statusCode: number;
}

function isObject(input: unknown): input is Record<string, unknown> {
  return typeof input === 'object' && input !== null && !Array.isArray(input);
}

function isApiError(input: unknown): input is ApiError {
  return isObject(input) && typeof input.code === 'string' && typeof input.error === 'string';
}

class ApiClient {
  constructor(private baseUrl: string, private getToken: () => Promise<string>) {
    if (isEmpty(baseUrl)) {
      throw new Error(
        'Cannot instantiate ApiClient with an empty baseUrl. Verify the value of REACT_APP_API_URL in the .env file.',
      );
    }
  }

  private async fetch<TResponse = undefined>(
    method: 'POST' | 'GET',
    path: string,
    body?: Record<string, unknown>,
  ): Promise<TResponse> {
    const token = await this.getToken();

    const response = await fetch(`${this.baseUrl}${path}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: method === 'GET' ? undefined : JSON.stringify(body ?? {}),
    });

    // Some calls return an empty (non-JSON) response which makes the `.json()` throw.
    // As this is expected, we catch without consequence.
    const data: TResponse | ApiError = await response.json().catch(() => null);

    if (isApiError(data)) {
      throw new Error(data.message);
    }

    return data;
  }

  async post<TResponse>(path: string, body: Record<string, unknown> = {}) {
    return this.fetch<TResponse>('POST', path, body);
  }

  async get<TResponse>(path: string) {
    return this.fetch<TResponse>('GET', path);
  }
}

export function useApiClient() {
  const baseUrl = process.env.REACT_APP_API_URL ?? '';
  const { isAuthenticated, getAccessTokenSilently } = useAuthentication();

  if (!isAuthenticated) {
    throw new Error('Not authenticated. Cannot create ApiClient.');
  }

  return new ApiClient(baseUrl, getAccessTokenSilently);
}
