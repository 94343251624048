import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Simulator } from '~utils/types';
import { useApiClient } from '../utils/useApiClient';

type UpdatePowerSettingsModalProps = {
  simulator: Simulator;
  onSuccess: () => Promise<void> | void;
} & Pick<UseDisclosureReturn, 'isOpen' | 'onClose'>;

export function UpdatePowerSettingsModal({
  simulator,
  onSuccess,
  isOpen,
  onClose,
}: UpdatePowerSettingsModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <UpdatePowerSettingsModalContent
          simulator={simulator}
          onSuccess={onSuccess}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  );
}

type UpdatePowerSettingsModalContentProps = Pick<
  UpdatePowerSettingsModalProps,
  'simulator' | 'onSuccess' | 'onClose'
>;

function UpdatePowerSettingsModalContent({
  simulator,
  onSuccess,
  onClose,
}: UpdatePowerSettingsModalContentProps) {
  const schema = useMemo(
    () =>
      z.union([
        z.object({
          type: z.literal('power_mode'),
          mode: z.enum(['1', '2', '3', '4']),
        }),
        z.object({
          type: z.literal('apm'),
          status: z.enum(['off', 'mode_1', 'mode_2']),
        }),
        z.object({
          type: z.literal('stand_by_mode'),
          mode: z.enum(['green', 'fast']),
        }),
        z.object({
          type: z.literal('always_on'),
        }),
      ]),
    [],
  );

  type FormValues = z.TypeOf<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    defaultValues: simulator.shadow.state.powerSettings?.value,
    resolver: zodResolver(schema),
  });

  const apiClient = useApiClient();

  const performSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await apiClient.post(`/simulators/${simulator.id}/changePowerSettings`, {
          powerSettings: values,
        });

        await onSuccess();
        onClose();
      } catch (err) {
        console.error('An error occured trying to update power settings', err);
      }
    },
    [onSuccess, simulator, onClose, apiClient],
  );

  return (
    <form onSubmit={handleSubmit(performSubmit)}>
      <ModalHeader>Update Power Settings</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <>
              <FormControl>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Select a type"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                >
                  <option value="power_mode">Power Mode</option>
                  <option value="apm">Advanced Power Management (APM)</option>
                  <option value="stand_by_mode">Stand By Mode (JEDI)</option>
                  <option value="always_on">Always On (tablet)</option>
                </Select>
                <FormHelperText>{errors.type?.message}</FormHelperText>
              </FormControl>
              {field.value === 'power_mode' && (
                <Controller
                  control={control}
                  name="mode"
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>Power Mode</FormLabel>
                      <Select
                        placeholder="Select a power mode"
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </Select>
                      <FormHelperText>{errors.root?.mode?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              )}
              {field.value === 'apm' && (
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>APM Status</FormLabel>
                      <Select
                        placeholder="Select an APM status"
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      >
                        <option value="off">Off</option>
                        <option value="mode_1">Mode 1</option>
                        <option value="mode_2">Mode 2</option>
                      </Select>
                      <FormHelperText>{errors.root?.status?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              )}
              {field.value === 'stand_by_mode' && (
                <Controller
                  control={control}
                  name="mode"
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel>Stand By Mode</FormLabel>
                      <Select
                        placeholder="Select a mode"
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      >
                        <option value="green">Green</option>
                        <option value="fast">Fast</option>
                      </Select>
                      <FormHelperText>{errors.root?.mode?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              )}
            </>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>
          Cancel
        </Button>
        <Button type="submit" colorScheme="blue" isDisabled={isSubmitting} isLoading={isSubmitting}>
          Update
        </Button>
      </ModalFooter>
    </form>
  );
}
