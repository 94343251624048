import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';
import { z } from 'zod';
import { Simulator } from '~utils/types';
import { useApiClient } from '../utils/useApiClient';

const schema = z.object({
  activeMacAddress: z.string().optional(),
  localIPAddress: z.string().optional(),
});

interface Props {
  simulator: Simulator;
  refetch: () => Promise<QueryObserverResult<Simulator, unknown>>;
}
export default function ModalConfiguration({ simulator, refetch }: Props) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function onSuccess() {
    await refetch();
    onClose();
  }

  return (
    <>
      <Button onClick={onOpen} isDisabled={simulator.connectionState === 'connected'}>
        Change Configuration
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalConfigurationContent
            simulatorId={simulator.id}
            initialFocusRef={initialFocusRef}
            onSuccess={onSuccess}
            onCancel={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

type ContentProps = {
  simulatorId: string;
  initialFocusRef: MutableRefObject<HTMLInputElement | null>;
  onCancel: () => Promise<void> | void;
  onSuccess: () => Promise<void> | void;
};

function ModalConfigurationContent({
  simulatorId,
  initialFocusRef,
  onCancel,
  onSuccess,
}: ContentProps) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      activeMacAddress: '',
      localIPAddress: '',
    },
    resolver: zodResolver(schema),
  });

  const {
    ref: macInputRef,
    onChange: onMacChange,
    ...macInputProps
  } = register('activeMacAddress');

  const apiClient = useApiClient();

  const { ref: ipInputRef, onChange: onIpChange, ...ipInputProps } = register('localIPAddress');

  const onSubmit = useCallback(
    async ({ activeMacAddress, localIPAddress }: z.TypeOf<typeof schema>) => {
      const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
      const ipRegex = /(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

      if (activeMacAddress && !macRegex.test(activeMacAddress)) {
        setError('activeMacAddress', { message: 'The MAC address is invalid.' });
        return;
      }

      if (localIPAddress && !ipRegex.test(localIPAddress)) {
        setError('localIPAddress', { message: 'The IP address is invalid.' });
        return;
      }

      clearErrors();

      await apiClient.post(`/simulators/${simulatorId}/configure`, {
        activeMacAddress,
        localIPAddress,
      });

      await onSuccess();
    },
    [clearErrors, onSuccess, setError, simulatorId, apiClient],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Set new configuration</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <FormLabel htmlFor="activeMacAddress" mt={3}>
          Active MAC Address (optional)
        </FormLabel>
        <FormControl isInvalid={Boolean(errors.activeMacAddress?.message)}>
          <Input
            id="activeMacAddress"
            placeholder="00-D0-56-F2-B5-12"
            ref={(r) => {
              initialFocusRef.current = r;
              macInputRef(r);
            }}
            onChange={onMacChange}
            {...macInputProps}
          />
          <FormErrorMessage>{errors.activeMacAddress?.message}</FormErrorMessage>
        </FormControl>

        <FormLabel htmlFor="ipAddress" mt={3}>
          Serial Number (optional)
        </FormLabel>

        <FormControl isInvalid={Boolean(errors.localIPAddress?.message)}>
          <Input
            id="ipAddress"
            placeholder="192.168.31.2"
            ref={(r) => {
              initialFocusRef.current = r;
              ipInputRef(r);
            }}
            onChange={onIpChange}
            {...ipInputProps}
          />
          <FormErrorMessage>{errors.localIPAddress?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>

      <ModalFooter mt={3}>
        <Button variant="ghost" onClick={onCancel} isDisabled={isSubmitting}>
          Close
        </Button>

        <Button type="submit" colorScheme="blue" isDisabled={isSubmitting} isLoading={isSubmitting}>
          Send
        </Button>
      </ModalFooter>
    </form>
  );
}
