import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Spinner, Table, Tbody, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Simulator } from '~utils/types';

interface Props {
  simulators: Simulator[];
}

export default function SimulatorTable({ simulators }: Props) {
  const navigate = useNavigate();

  return (
    <VStack mt="20" spacing="12">
      <Table width="4xl" rounded="md" p="2" mx="5" variant="simple">
        <Thead spacing="12" pt="2" pb="4" w="full">
          <Tr>
            <Th>STATUS</Th>
            <Th>ALIAS</Th>
            <Th>SERIAL NUMBER</Th>
            <Th>CONTENT SOURCE</Th>
            <Th>PLATFORM</Th>
            <Th>BEHAVIOR</Th>
          </Tr>
        </Thead>
        <Tbody>
          {simulators.map((simulator) => {
            const contentSourceValue = simulator.shadow.state.contentSource?.value;

            const contentSource =
              contentSourceValue?.type === 'app'
                ? contentSourceValue.label ?? 'APP'
                : contentSourceValue?.type === 'bookmark'
                ? 'BROWSER'
                : contentSourceValue?.type === 'input'
                ? contentSourceValue.source
                : contentSourceValue?.type === 'playlist'
                ? 'MEDIA PLAYER'
                : 'UNKNOWN';

            return (
              <Tr
                key={simulator.id}
                onClick={() => {
                  navigate(`/simulators/${simulator.id}`);
                }}
                _hover={{
                  background: 'blue.50',
                  cursor: 'pointer',
                }}
              >
                <Td>
                  {simulator.connectionState === 'disconnected' ? (
                    <WarningIcon color="red.500" />
                  ) : simulator.connectionState === 'connected' ? (
                    <CheckCircleIcon color="green.500" />
                  ) : (
                    <Spinner color="blue.400" />
                  )}
                </Td>
                <Td>{simulator.metadata.alias ?? simulator.configuration.serialNumber}</Td>
                <Td>{simulator.configuration.serialNumber}</Td>
                <Td>{contentSource}</Td>
                <Td>{simulator.configuration.platform.name}</Td>
                <Td>{simulator.metadata.behaviour}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </VStack>
  );
}
